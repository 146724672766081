import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { getGlossaryLabel, ListItemsType } from "../../SearchView";
import { GlossaryItem, GlossaryItemType, RosettaItem } from "../../../data/Data";
import { AppModel } from "../../../store/appModel";
import { useStoreState } from "easy-peasy";
import { useState, useEffect } from "react";
import { TypeRosettaView } from "./TypeRosettaView";

export function TypeGlossaryView(props: { types: string[] }) {

    if (!props.types) return <>
        <h3>Inga termer angivna</h3>
    </>

    return <>
        <Tabs>
            <TabList>
                <Tab>Ordlista</Tab>
                <Tab>Övningar</Tab>
            </TabList>
            <TabPanels>
                <TabPanel><div className="Frame" style={{ marginTop: '.5rem' }}>
                    {props.types.map((type, index) => {
                        return <>
                            <h3>{getGlossaryLabel(type)}</h3>
                            <ListItemsType key={index} type={type as GlossaryItemType} />
                        </>
                    })}
                </div></TabPanel>
                <TabPanel>  <div className="Frame" style={{ marginTop: '.5rem' }}>
                    <GlossaryRosettaView types={props.types} />
                </div></TabPanel>
            </TabPanels>
        </Tabs >

    </>
}

export function GlossaryRosettaView(props: { types: string[] }) {
    const [counter, setCounter] = useState(0);
    const [rosettaItems, setRosettaItems] = useState<RosettaItem[]>([]);
    const glossaryItems: GlossaryItem[] = useStoreState<AppModel>((state) => state.glossary.items);
    const filteredItems = glossaryItems.filter(item => props.types.includes(item.type));

    useEffect(() => {
        let shuffledItems = filteredItems
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        const glossaryRosettaItems: RosettaItem[] = shuffledItems.map(item => { return { a: item.text, q: item.definition } });

        setRosettaItems(glossaryRosettaItems.slice(0, 4));
    }, [counter]);

    useEffect(() => {
        setCounter(counter + 1);
        setRosettaItems([]);
    }, [props.types]);

    return <>
        {/* <button onClick={e => { setCounter(counter + 1) }}>Ny giv</button> */}
        <TypeRosettaView items={rosettaItems} onReset={() => {
            setCounter(counter + 1);
        }} />
    </>
}