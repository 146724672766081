import { Action, Computed, FilterActionTypes, Helpers, StateMapper, Thunk, action, computed, thunk } from "easy-peasy";
import { AppModel } from "./appModel";
import { v4 as uuidv4 } from 'uuid';
import { getDatabase, ref, onValue, DatabaseReference, get, child, set, remove } from "firebase/database";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { UserAccess, UserData } from "./userdata";
import { Access, Thread, ThreadItem, Item, ItemType, Publish, itemToObject, EventItem, GlossaryItem, GlossaryLink } from "../data/Data";
import { createTimePath, emailToRefPath, getTimePath, pathToEmail } from "../utils/utils";
import { Recording, ScorxItem } from "../components/music/player/PlayerTypes";
import { randomUUID } from "crypto";
import md5 from "md5";
import { KDistActive, KDistOmdome, KdistUserConnection } from "./kdistcontent";
// import { getUuid } from "pdfjs-dist/types/src/shared/util";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DB,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// callable functions from firebase functions
export const serverHelloWorld = httpsCallable(functions, 'helloWorld');
export const serverCreateUser = httpsCallable(functions, 'createUser');
export const serverTest = httpsCallable(functions, 'test');
export const serverAddUser = httpsCallable(functions, 'addUser');
export const serverUidExists = httpsCallable(functions, 'uidExists');
export const serverDeleteUser = httpsCallable(functions, 'deleteUser');

serverHelloWorld().then((result) => {
    console.log('helloWorld', result.data);
});

export type FirebaseModel = {
    test: Thunk<FirebaseModel, any>;
    addUser: Thunk<FirebaseModel, NewFirebaseUser>;
}

export const createFirebase = (): FirebaseModel => {
    return {
        test: thunk(async (actions, payload, helpers: Helpers<FirebaseModel, AppModel, any>) => {
            console.log('test', payload);
            serverTest(payload).then((result) => {
                console.log('test result:', result.data);
            });
        }),
        addUser: thunk(async (actions, payload, helpers: Helpers<FirebaseModel, AppModel, NewFirebaseUser>) => {
            console.log('addUser', payload);
            serverAddUser(payload).then((result) => {
                console.log('addUser result:', result.data);
            }).catch((error) => {
                console.log('addUser error:', error);
            });
        }),
    }
}

export type NewFirebaseUser = {
    email: string,
    password: string,
    uid: string,
    userdata: UserData,
    kakVerifyDate: string | null,
}

//-----------------------------------------------------------------------

export const getUserData = async (uid: string) => {
    uid = emailToRefPath(uid);
    const userRef: DatabaseReference = ref(database, `users/${uid}`);
    const snapshot = await get(userRef);
    const val = snapshot.val();
    return val;
};

export const getUsersData = async () => {
    const userRef: DatabaseReference = ref(database, `users`);
    const snapshot = await get(userRef);
    const val = snapshot.val();
    return val;
};

export type UserDbData = { email: string, firstName: string, lastName: string, kakSource: string, kakVerifyDate: string, uid: string, claims: string };


export const getUsersDataArray = async () => {
    const val = await getUsersData();
    const users: UserDbData[] = [];
    Object.entries(val).forEach(([key, value], idx) => {
        const v: any = value;
        users.push({ email: v.email, firstName: v.firstName, lastName: v.lastName, kakSource: v.kakSource, kakVerifyDate: v.kakVerifyDate, uid: key, claims: JSON.stringify(v.claims) });
    });
    return users;
}

//-----------------------------------------------------------------------

export const getContentData = async () => {
    const userRef: DatabaseReference = ref(database, `data`);
    const snapshot = await get(userRef);
    const val = snapshot.val();
    return val;
}

export const setUserDataCurrentAccess = async (uid: string, userAccess: UserAccess) => {
    const userRef: DatabaseReference = ref(database, `users/${uid}/claims/current`);
    set(userRef, userAccess);
}

export const setUserDataClaimsAccesses = async (uid: string, userAccesses: UserAccess[]) => {
    const userRef: DatabaseReference = ref(database, `users/${uid}/claims/accesses/`);
    set(userRef, userAccesses);
}

export const rdbRemoveUserData = async (uid: string) => {
    const userRef: DatabaseReference = ref(database, `users/${uid}`);
    const snapshot = await get(userRef);
    const val = snapshot.val();
    const ref2: DatabaseReference = ref(database, `deleted-users/${uid}`);
    set(ref2, val);
    remove(userRef);
}

export const rdbUpdateKakVerifyDate = async (uid: string, kakVerifyDate: string) => {
    const userRef: DatabaseReference = ref(database, `users/${uid}/kakVerifyDate`);
    set(userRef, kakVerifyDate);
}

export const rdbSetKakUserSource = async (uid: string, source: string) => {
    const userRef: DatabaseReference = ref(database, `users/${uid}/kakSource`);
    set(userRef, source);
}

export const saveContentItem = async (item: Item, path: string) => {
    console.log(JSON.stringify(item));
    //const userRef: DatabaseReference = ref(database, `data2`);
    //return set(userRef, item);
}

export const saveItemText = async (text: string, path: string) => {
    console.log(path);
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/text';
    console.log(text);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, text);
    } catch (error) {
        alert(error);
    }
}

export const saveItemTitle = async (title: string, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/title';
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, title);
    } catch (error) {
        alert(error);
    }
}

export const saveItemSort = async (sort: number, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/sort';
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, sort);
    } catch (error) {
        alert(error);
    }
}

export const saveItemPublish = async (publish: Publish, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/publish';
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, publish);
    } catch (error) {
        alert(error);
    }
}

export const saveItemAccess = async (access: Access, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/access';
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, String(access));
    } catch (error) {
        alert(error);
    }
}

export const saveItemType = async (type: ItemType, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/type';
    console.log("saveItemType", fullpath, type);

    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, type);
    } catch (error) {
        alert(error);
    }
}



export const saveItemChildren = async (item: Item, path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/') + '/children';
    let obj = itemToObject(item);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, obj.children);
    } catch (error) {
        alert(error);
    }
}

export const deleteItem = async (path: string) => {
    const fullpath = 'data' + path.replaceAll('/', '/children/')
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await remove(userRef);
    } catch (error) {
        alert(error);
    }
}

//---------------------------------------------------------------------------

export const saveScorxItem = async (scorxItem: ScorxItem) => {
    const fullpath = 'scorx/' + scorxItem.scorxId;
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, scorxItem);
    } catch (err) {
        alert(err);
    }
}

export const saveScorxItemRecording = async (scorxId: string, recording: Recording) => {
    const fullpath = 'scorx/' + scorxId + '/recordings/' + recording.id;
    console.log(fullpath);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, recording);
    } catch (err) {
        alert(err);
    }
}

export const getScorxList = async () => {
    const fullpath = 'scorx/';
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(userRef);
        const val = snapshot.val();
        return val;
    } catch (err) {
        alert(err);
    }

}


//-----------------------------------------------------------------------
const THREAD_PATH_SLASH = '_&&_';
export const createDiscussion = async (roomId: string, path: string, discussion: Thread) => {
    path = path.startsWith('/') ? path.substring(1) : path;
    const fullpath = 'discussions' + '/' + roomId + '/' + path.replaceAll('/', THREAD_PATH_SLASH);

    try {
        const userRef: DatabaseReference = ref(database, fullpath);

        await set(userRef, discussion);
    } catch (error) {
        alert(error);
    }
}

export const getDiscussion = async (roomId: string, path: string) => {
    // const fullpath = 'discussions' + '/' + path.replaceAll('/', '/children/');
    path = path.startsWith('/') ? path.substring(1) : path;
    const fullpath = 'discussions' + '/' + roomId + '/' + path.replaceAll('/', THREAD_PATH_SLASH);
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(userRef);
        const val = snapshot.val();
        return val;
    } catch (error) {
        alert(error);
    }
}

export const addDiscussionItem = async (roomId: string, path: string, discussion: Thread, ditem: ThreadItem) => {
    const time = getTimePath();
    path = path.startsWith('/') ? path.substring(1) : path;
    const basepath = 'discussions' + '/' + roomId + '/' + path.replaceAll('/', THREAD_PATH_SLASH);
    const fullpath = basepath + '/items/' + time;
    const threadpath = basepath + '/updated';

    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, ditem);

        const threadpath = basepath + '/updated';
        const userRefThread: DatabaseReference = ref(database, threadpath);
        await set(userRefThread, time);

        const threadpathstatus = basepath + '/status';
        const userRefThreadStatus: DatabaseReference = ref(database, threadpathstatus);
        await set(userRefThreadStatus, "ongoing");

    } catch (error) {
        alert(error);
    }
}

export const getDiscussions = async (roomId: string) => {
    const fullpath = `discussions/${roomId}`;
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(userRef);
        const val = snapshot.val();
        return val;
    } catch (error) {
        alert(error);
    }
}

export const createEvent = async (roomId: string, item: EventItem) => {
    const timepath = createTimePath(new Date());
    const fullpath = `events/${roomId}/${timepath}/`;
    console.log(JSON.stringify(item));

    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        await set(userRef, item);
    } catch (error) {
        alert(error);
    }
}

export const removeEvent = async (roomId: string, item: EventItem) => {
    alert("removeEvent");
}

export const getEvents = async (roomId: string) => {
    const fullpath = `events/${roomId}`;
    try {
        const userRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(userRef);
        const val = snapshot.val();
        return val;
    } catch (error) {
        alert(error);
    }
}

//-----------------------------------------------------------------------------

export const createBookmark = async (userId: string, roomId: string, path: string, title: string, shelfTitle: string, bookTitle: string) => {
    let path2 = path.replaceAll('/', '_&&_');
    const fullpath = `user-bookmarks/${emailToRefPath(userId)}/${roomId}/${path2}`;
    console.log(fullpath);
    const timepath = createTimePath(new Date());
    const obj = { time: timepath, title: title, bookTitle, shelfTitle };
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, obj);
    } catch (error) {
        alert(error);
    }
}

export const removeBookmark = async (userId: string, roomId: string, path: string) => {
    let path2 = path.replaceAll('/', '_&&_');
    const fullpath = `user-bookmarks/${emailToRefPath(userId)}/${roomId}/${path2}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await remove(dbRef);
    } catch (error) {
        alert(error);
    }
};

export const getBookmarksUser = async (userId: string, roomId: string) => {
    const fullpath = `user-bookmarks/${emailToRefPath(userId)}/${roomId}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

export const getBookmarkUser = async (userId: string, roomId: string, path: string) => {
    let path2 = path.replaceAll('/', '_&&_');
    const fullpath = `user-bookmarks/${emailToRefPath(userId)}/${roomId}/${path2}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

//-----------------------------------------------------------------------------

export const CURRENT_CONFIRMATION_TABLE = 'user-confirm2025Jan';

export const confirmCourse = async (userId: string, fullname: string) => {
    const fullpath = `${CURRENT_CONFIRMATION_TABLE}/${emailToRefPath(userId)}`;
    const timepath = createTimePath(new Date());
    const obj = { time: timepath, fullname: fullname };
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, obj);

    } catch (error) {
        alert(error);
    }
}


export const getConfirmCourse = async (userId: string) => {
    const fullpath = `${CURRENT_CONFIRMATION_TABLE}/${emailToRefPath(userId)}`;
    console.log('getConfirmCourse', userId, fullpath);

    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

export const rdbGetConfirms = async () => {
    const fullpath = `${CURRENT_CONFIRMATION_TABLE}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

export type ConfirmedUser = { username: string, fullname: string, confirmed: boolean }

export const rdbGetConfirmsArray = async () => {
    const val = await rdbGetConfirms();
    const items: ConfirmedUser[] = [];
    Object.entries(val).forEach(([key, value], idx) => {
        try {
            const v: any = value;
            items.push({ username: key, fullname: v.fullname, confirmed: true });
        } catch (e) {
            console.log(e);
        }
    });
    console.log(items);
    return items;
}

export type ConfirmedUserExt = { username: string, fullname: string, confirmed: boolean, email: string, kakSource: string, kakVerifyDate: string }
export const rdbGetConfirmsExt = async () => {
    const confirms = await rdbGetConfirmsArray();
    const users = await getUsersDataArray();

    const exts: ConfirmedUserExt[] = [];
    confirms.forEach((c) => {
        const user = users.find((u) => u.uid === c.username);
        if (user) {
            exts.push({ username: c.username, fullname: user.firstName + ' ' + user.lastName, confirmed: c.confirmed, email: user.email, kakSource: user.kakSource, kakVerifyDate: user.kakVerifyDate });
        }
    });
    console.log(exts);
    return exts;
}


//----------------------------------------------------------------------------

export const logUserAction = async (uid: string, action: string, data: any) => {
    uid = emailToRefPath(uid);
    const timepath = createTimePath(new Date());
    const fullpath = `user-actions/${uid}/${timepath}`;
    const obj = { time: timepath, action: action, data: data };
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, obj);
    } catch (error) {
        alert(error);
    }
}

//-------------------------------------------------------------------------------

export const rdbGetActions = async () => {
    const fullpath = `user-actions`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

export const rdbGetUserActions = async (uid: string) => {
    uid = emailToRefPath(uid);
    const fullpath = `user-actions/${uid}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}


//-----------------------------------------------------------------------

export const rdbSaveGlossarItem = async (item: GlossaryItem) => {
    if (!item.id) {
        item.id = uuidv4();
    }
    const fullpath = `glossary/${item.id}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, item);
    } catch (error) {
        alert(error);
    }
}

export const rdbRemoveGlossarItem = async (id: string) => {
    const fullpath = `glossary/${id}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await remove(dbRef);
    } catch (error) {
        alert(error);
    }
}

export const rdbGetGlossaryItem = async (id: string): Promise<GlossaryItem | undefined> => {
    const fullpath = `glossary/${id}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const item = await get(dbRef);
        return item.val();
    } catch (error) {
        alert(error);
    }
}

export const rdbGetGlossaryItems = async () => {
    const fullpath = `glossary/`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        // console.log(val);
        return val;
    } catch (error) {
        alert(error);
    }
}

export const rdbGetGlossaryItemsArray = async () => {
    const val = await rdbGetGlossaryItems();
    const items: GlossaryItem[] = [];
    Object.entries(val).forEach(([key, value], idx) => {
        try {
            const v: any = value;
            items.push(
                {
                    id: v.id, text: v.text,
                    definition: v.definition,
                    info: v.info,
                    language: v.language,
                    links: v.links,
                    type: v.type,
                    sort: v.sort,
                    picUrl: v.picUrl
                });
        } catch (e) {
            console.log(e);
        }
    });
    // console.log(items);
    return items;
}

export const rdbSetGlossarLinks = async (itemId: string, item: GlossaryLink[]) => {
    const fullpath = `glossary/${itemId}/links`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, item);
    } catch (error) {
        alert(error);
    }
}

export const rdbSetItemGlossaryType = async (itemPath: string, type: string) => {
    const fullpath = `$itemPath/${itemId}/links`;

}

//-----------------------------------------------------------------------



export const rgbSaveKdistUserItemConnection = async (uid: string, itemId: string, connectionStatus: KDistActive) => {
    uid = emailToRefPath(uid);
    const fullpath = `kdist-user-item-connection/${uid}/${itemId}/active/`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        console.log('save connectionStatus', connectionStatus);
        await set(dbRef, connectionStatus);
    } catch (error) {
        alert(error);
    }

}

export const rgbSaveKdistUserItemOmdome = async (uid: string, itemId: string, omdome: KDistOmdome) => {
    uid = emailToRefPath(uid);
    const fullpath = `kdist-user-item-connection/${uid}/${itemId}/omdome/`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        await set(dbRef, omdome);
    } catch (error) {
        alert(error);
    }
}

export const rdbKdistLoadConnectedItems = async (uid: string) => {
    uid = emailToRefPath(uid);
    const fullpath = `kdist-user-item-connection/${uid}`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        return val;
    } catch (error) {
        alert(error);
    }
}

export const rdbKdistLoadConnectedItemsArray = async (uid: string): Promise<{ uid: string, itemId: string, status: KDistActive }[]> => {
    const val = await rdbKdistLoadConnectedItems(uid);
    const items: any[] = [];
    Object.entries(val).forEach(([key, value], idx) => {
        try {
            const v: any = value;
            items.push({ uid: uid, itemId: key, active: v.active });
        } catch (e) {
            console.log(e);
        }
    });

    console.log('items', items);

    return items;
}

export const rdbKdistLoadAllConnectionsItems = async () => {
    const fullpath = `kdist-user-item-connection`;
    try {
        const dbRef: DatabaseReference = ref(database, fullpath);
        const snapshot = await get(dbRef);
        const val = snapshot.val();
        return val;
    } catch (error) {
        alert(error);
    }
}

export const rdbKdistLoadAllConnectionsItemsArray = async (): Promise<KdistUserConnection[]> => {
    const val = await rdbKdistLoadAllConnectionsItems();
    const items: any[] = [];
    if (!val) return items;
    Object.entries(val).forEach(([key, value], idx) => {
        try {
            const v: any = value;
            console.log('v', v);
            Object.entries(v).forEach(([key2, value2], idx) => {
                items.push({ uid: pathToEmail(key), itemId: key2, active: value2.active });
            });
        } catch (e) {
            console.log(e);
        }
    });

    console.log('items2', items);

    return items;

}
